import { useState } from 'react';
import { ethers } from 'ethers';
import { useMountEffect } from '@react-hookz/web';
import ShuttlebunniesEggs from '../artifacts/contracts/ShuttlebunniesEggs.sol/ShuttlebunniesEggs.json';
import Shuttlebunnies from '../artifacts/contracts/Shuttlebunnies.sol/Shuttlebunnies.json';

export const useContract = () => {
  const mainContractAddress = process.env.REACT_APP_MAIN_CONTRACT_ADDRESS;
  const secondaryContractAddress = process.env.REACT_APP_SECONDARY_CONTRACT_ADDRESS;
  const [contract, setContract] = useState<{ primary: any; secondary: any }>({ primary: {}, secondary: {} });

  // If ethereum is not present we cannot contract a contract and need useAccount to take precedent to trigger onboarding
  if(!window.ethereum) return {};

  // @ts-ignore
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMountEffect(async () => {
    if (!mainContractAddress) return console.warn('No Main contract address has been set.');
    if (!secondaryContractAddress) return console.warn('No Secondary contract address has been set.');

    // Shuttlebunnies eggs contract
    const primary = new ethers.Contract(
      mainContractAddress,
      ShuttlebunniesEggs.abi,
      signer,
    );
    // Shuttlebunnies hatched contract
    const secondary = new ethers.Contract(
      secondaryContractAddress,
      Shuttlebunnies.abi,
      signer,
    );
    return setContract({ primary, secondary });
  });

  return { contract, provider };
};
