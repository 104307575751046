import React, {useEffect, useCallback} from 'react';
import {Alert, Box, Button, Slider, Typography} from "@mui/material";
import {MintModal} from "./MintModal";
import {useAccount} from "../hooks/useAccount";
import {useContract} from "../hooks/useContract";
import progressBar from '../assets/progressBar.png';
import smallEgg from '../assets/smallEgg.png';
import EggPage from "./EggPage";

const MAX_SUPPLY = 300;

const Minter = () => {
  const {
    accounts, networkError
  } = useAccount();
  const { contract } = useContract();

  const [open, setOpen] = React.useState(false);
  const [eggPage, setEggPage] = React.useState(false);
  const [eggBalance, setEggBalance] = React.useState<number>(0);
  const [hatchBalance, setHatchBalance] = React.useState<number>(0);
  const [totalMinted, setTotalMinted] = React.useState<number>(0);

  const getBalance: () => Promise<void> = useCallback(async () => {
    if(!contract || !accounts.length) return;
    const eggTokens = await contract.primary.walletOfOwner(accounts[0]);
    setEggBalance(eggTokens.length);
    const hatchTokens = await contract.secondary.walletOfOwner(accounts[0]);
    setHatchBalance(hatchTokens.length);
    const totalMinted = await contract.primary.getTotalMinted();
    setTotalMinted(totalMinted);
  }, [contract, accounts])

  // TODO: Need  total minted for the progress
  useEffect(() => {
    getBalance();
  }, [accounts, contract, getBalance])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    await getBalance()
    setOpen(false);
  };

  const handleEggPageClick = () => {
    setEggPage(true)
  }

  const makeTotalValueProgress = (value = 0, maxValue: number) => (value * 100) / maxValue;

  return (
    <>
      {eggPage ? <EggPage /> : null}
      {(accounts.length !== 0 && !eggPage) ? <Box sx={{
          display: 'flex', alignItems: 'center', flexDirection: 'column', my: 2, height: '100%'
        }}
        gap={4}
        >
        {networkError
          ? <Alert severity="warning" sx={{ width: '100%', justifyContent: 'center' }}>
            You have selected the wrong network.
            Please switch to the Polygon network on your wallet.
            {' '}
            <a
              href="https://medium.com/stakingbits/setting-up-metamask-for-polygon-matic-network-838058f6d844"
              target="_blank"
              rel="noreferrer"
            >
              Go to this article for more information on how to switch to the Polygon network
            </a>
            .
          </Alert>
          : <>
            <Box sx={{ my: 2, width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Slider
                sx={{
                  margin: 'auto', width: '75%', height: '25px', borderRadius: '15px', color: 'rgba(57,35,8,0.5)', '& .MuiSlider-track': { backgroundImage: `url(${progressBar})` },
                }}
                components={{
                  // 4% is the size of the egg relatively to the Slider
                  Thumb: () => <Box sx={{ backgroundImage: `url(${smallEgg})`, width: '50px', height: '50px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', left: `calc(${makeTotalValueProgress(totalMinted, MAX_SUPPLY)}% - 4%)`, top: '0px',  cursor: 'default' }} />,
                }}
                value={totalMinted}
                valueLabelDisplay="auto"
                min={0}
                max={MAX_SUPPLY}
              />
              <Typography >Total minted: {totalMinted}/{MAX_SUPPLY}</Typography>
            </Box>
            <Box sx={{ display: 'flex', my: 2 }} gap={4}>
              {eggBalance > 0 ? <Button onClick={handleEggPageClick} variant="contained" color="secondary" size="large">Visit your egg</Button> : null}
              {hatchBalance > 0 ? <Button onClick={handleEggPageClick} variant="contained" color="secondary" size="large">Visit your bunny</Button> : null}
              {(eggBalance === 0 && hatchBalance === 0) ? <Button onClick={handleClickOpen} variant="contained" size="large">
                Hunt for egg
              </Button> : null}
            </Box>
          </>}
        </Box>
        : null}
        <MintModal open={open} handleClose={handleClose} />
      </>
  );
};

export default Minter;