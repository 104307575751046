import { createTheme } from '@mui/material';
// @ts-ignore
import HVD_Comic_Serif_Pro from './fonts/HVD_Comic_Serif_Pro.otf';

const theme = createTheme({
  palette: {
    primary: {
      main: '#C65275',
    },
    secondary: {
      main: '#8DCEA4',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'Quicksand',
      'HVD_Comic_Serif_Pro',
    ].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'HVD_Comic_Serif_Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HVD_Comic_Serif_Pro'), local('HVD_Comic_Serif_Pro-Regular'), url(${HVD_Comic_Serif_Pro}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export default theme;
