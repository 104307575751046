import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {useContract} from "../hooks/useContract";
import {useAccount} from "../hooks/useAccount";
import eggPouring from '../assets/eggPouring.gif';
import animated_bunny from '../assets/animated_bunny.gif';

type ModalProps = {
  open: boolean;
  handleClose: () => void;
}

const poll = async (fn: any, arg: any, ms: any) => {
  let result = await fn.getTransactionReceipt(arg);
  while (result === null) {
    await wait(ms);
    result = await fn.getTransactionReceipt(arg);
  }
  return result;
}

const wait = (ms = 1000) => {
  return new Promise(resolve => {
    console.log(`waiting ${ms} ms...`);
    setTimeout(resolve, ms);
  });
}


export const MintModal = ({ open, handleClose }: ModalProps) => {
  const { accounts } = useAccount();
  const { contract, provider } = useContract();
  const [mintedNftId, setMintedNftId] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  const getMintedNft = async () => {
    let walletIds = await contract?.primary.walletOfOwner(accounts[0]);
    while (walletIds.length  === 0) {
      console.log('walletIds empty')
      await wait();
      walletIds = await contract?.primary.walletOfOwner(accounts[0]);
    }
    const latestId = walletIds[walletIds.length - 1];
    // @ts-ignore
    setMintedNftId(latestId);
    setLoading(false)
  }

  const handleClick = async () => {
    try {
      const mintData = await contract?.primary.safeEggMint(accounts[0], `${process.env.REACT_APP_IPFS_EGG_METADATA_CID}/{id}.json`);
      setLoading(true);
      await poll(provider, mintData.hash, 1000)
      await getMintedNft();
    } catch (e) {
      console.warn(e)
    }
  }

  const makeImage = () => {
    if(loading) return `url(${eggPouring})`;
    if(mintedNftId) return `url(https://gateway.pinata.cloud/ipfs/${process.env.REACT_APP_IPFS_EGG_IMAGE_CID}/${`${mintedNftId}`.padStart(5, '0')}.png)`;
    if(!loading && !mintedNftId) return `url(${animated_bunny})`;
  }

  const makeText = () => {
    if(loading)  return (
      <>
        <Typography variant="h5" fontWeight="bold">Minting...</Typography>
        <Typography>You will receive your egg very soon!</Typography>
      </>
    );
    if(mintedNftId) return (
      <>
        <Typography variant="h5" fontWeight="bold">Eggscellent!</Typography>
        <Typography>Your are now a Shuttlebunny parent!</Typography>
      </>
    );
    if(!loading && !mintedNftId) return (
      <>
        <Typography variant="h5" fontWeight="bold">You've just found something!</Typography>
        <Typography>Click the Mint button and claim your egg!</Typography>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent sx={{  backgroundColor: '#DDFFCE', display: 'flex', alignItems: 'center', gap: 3, p: 2 }}>
        <Box sx={{ backgroundImage: makeImage(), height: '300px', minWidth: '300px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', borderRadius: '5%' }} />
        <Box display="flex" flexDirection="column" gap={2}>
          {makeText()}
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: '#DDFFCE' }}>
        {mintedNftId ?
          <Button onClick={handleClose} sx={{ marginRight: 2 }}>Close</Button>
          :
          <>
          {!loading ? <Button variant="contained" onClick={handleClose}>Cancel</Button> : null}
            <Button variant="contained" onClick={handleClick} color="secondary" disabled={loading}>{loading ? 'Preparing...' : 'Mint Egg'}</Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
};
