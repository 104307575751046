import React, {ReactElement} from 'react';
import styled from 'styled-components';
import { Box, Button, Container, Typography  } from '@mui/material';
import { useAccount } from "./hooks/useAccount";
import BG from './assets/background.png';
import title from './assets/title.gif';
import metamaskBox from './assets/metamaskBox.png';
import rabbitBody from './assets/rabbitBody.png';
import Minter from "./components/Minter";
import metamask from "./assets/metamask.gif";
import SpeechBubble from "./components/SpeechBubble";

const BunnyBody = styled.div`
  background: url(${rabbitBody});
  background-size: contain;
  background-repeat: no-repeat;
  height: 350px;
  width: 200px;
  position: absolute;
  bottom: -5px;
  left: 350px;
`;

export function App(): ReactElement {
  const {
    accounts, accountButtonText, onAccountClick, isConnected
  } = useAccount();

  const makeLabel = (accountNumber: string) => `${accountNumber?.slice(0, 5)}...${accountNumber?.slice(accountNumber.length - 4, accountNumber.length)}`;

  return (
    <Box sx={{
      background: `url(${BG})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', width: '100%', maxWidth: '1920px', height: '100vh',  display: 'grid', margin: 'auto', flexDirection: 'column', padding: 2, gridTemplateRows: '1fr 2fr 2fr'
    }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ backgroundImage: `url(${metamaskBox})`, height: '125px', width: '255px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginLeft: 'auto', display: 'flex',  justifyContent: 'center', alignItems: 'center' }}>
          {isConnected ?
            <Box>
              <Typography>{makeLabel(accounts[0])}</Typography>
              {/*<Typography>{console.log(accounts[0])}</Typography>*/}
            </Box> :
            <Button onClick={onAccountClick} sx={{ fontWeight: 'bold', fontSize: '25px' }}><Box sx={{ backgroundImage: `url(${metamask})`, height: '60px', width: '75px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}} />{accountButtonText}</Button>
          }
        </Box>
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Container sx={{ display: 'flex', justifyContent: 'center', }}>
          <Box
            sx={{
              height: '450px', width: '750px', textAlign: 'center', display: 'flex', flexDirection: 'column', mt: 2, position: 'relative', p: 3
            }}
          >
            <Box sx={{ backgroundImage: `url(${title})`, minHeight: '100px', width: '700px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', margin: '0 auto 20px' }} />
            <Minter />
          </Box>
        </Container>
      </Box>
      <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <BunnyBody />
        <SpeechBubble />
      </Box>
    </Box>
  );
}
