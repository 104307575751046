import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';
import speechBubble from "../assets/speechBubble.png";
import { Button, Link } from "@mui/material";
import { useAccount } from "../hooks/useAccount";
import {useContract} from "../hooks/useContract";

const Bubble = styled.div`
  background: url(${speechBubble});
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
  width: 600px;
  font-family: 'HVD_Comic_Serif_Pro';
  font-size: 30px;
  padding: 50px 35px 95px 110px;
  display: flex;
  position: absolute;
  top: -90px;
  left: 525px;
  text-align: center;
  display: flex;
  align-items: center;
  span {
    margin: 0 auto;
  }
`;

const SpeechBubble = () => {
  const {
    onAccountClick, userLevel, accountButtonText, accounts
  } = useAccount();
  const { contract } = useContract();
  const [eggBalance, setEggBalance] = React.useState<number>();
  const [hatchBalance, setHatchBalance] = React.useState<number>();
  const [isHatchable, setIsHatchable] = useState(false);

  const getBalance: () => Promise<void> = useCallback(async () => {
    if(!contract || !accounts.length) return;
    const eggTokens = await contract.primary.walletOfOwner(accounts[0]);
    setEggBalance(eggTokens.length);
    const hatchTokens = await contract.secondary.walletOfOwner(accounts[0]);
    setHatchBalance(hatchTokens.length);
  }, [contract, accounts])

  const getHatchable: () => Promise<void> = useCallback(async () => {
    if(!contract || !accounts.length) return;
    const isHatchContractSet = await contract?.primary.getIsHatchContractSet();
    setIsHatchable(!isHatchContractSet);
  },  [contract, accounts])

  // TODO: Need  total minted for the progress
  useEffect(() => {
    getHatchable();
    getBalance();
  }, [accounts, contract, getBalance, getHatchable])

  // TODO: when meatmask not created we can see
  return (
    <Bubble className="text">
      {userLevel === 0 ? <Typist cursor={{ show: false, blink: false }} stdTypingDelay={0} startDelay={0}>
        <span>To Mint your Shuttlebunny you need to<Button component={Link} onClick={onAccountClick} sx={{ ml: 1, mr: 1, fontFamily: 'HVD_Comic_Serif_Pro', fontSize: '25px', '&.MuiTypography-root': { p: 0, height: '41px' } }}>{accountButtonText}</Button><span>your MetaMask wallet.</span></span>
      </Typist> : null}
      {userLevel === 1 ? <Typist cursor={{ show: false, blink: false }} stdTypingDelay={0} startDelay={0}>
        <span>Please<Button component={Link} onClick={onAccountClick} sx={{ mx: 2, fontFamily: 'HVD_Comic_Serif_Pro', fontSize: '25px', '&.MuiTypography-root': { p: 0, height: '41px' } }}>Connect</Button><span> your </span>MetaMask</span>
      </Typist> : null}
      {accounts.length > 0 && eggBalance === 0 && hatchBalance === 0 ? <Typist cursor={{ show: false, blink: false }}><span>Wooho! You made it! Add your wallet to this <Link href="https://docs.google.com/spreadsheets/d/15CTp9FTi64-_4ZowMBCVvDYxfZB6Zy9f02U0A5RouHc/edit?usp=sharing" target="_blank">list</Link> so we can send you some free matic!</span></Typist> : null}
      {isHatchable && eggBalance ? <Typist cursor={{ show: false, blink: false }} stdTypingDelay={0} startDelay={0}>
        <span>
          Golly Gosh! Look at that egg! It looks like it's almost ready to hatch!
        </span>
      </Typist> : null}
      {!isHatchable && eggBalance ? <Typist cursor={{ show: false, blink: false }} stdTypingDelay={0} startDelay={0}>
        <span>
          Son of bunny! The egg is ready to hatch! Go to check your egg!
        </span>
      </Typist> : null}
      {hatchBalance ? <Typist cursor={{ show: false, blink: false }} stdTypingDelay={0} startDelay={0}>
        <span>
          Congratulations my friend! You are now a ShuttleBunny parent!
        </span>
      </Typist> : null}
    </Bubble>
  );
};

export default SpeechBubble;