import { useState, useRef, useEffect } from 'react';
import MetaMaskOnboarding from '@metamask/onboarding';

export type TokenData = {
  name: string;
};

const POLYGON_NETWORK_CODE = '0x89';
const MUMBAI_NETWORK_CODE = '0x13881';
const CONNECT_TEXT = 'Connect';
const ONBOARD_TEXT = 'Create';

export const useAccount = () => {
  const [accountButtonText, setAccountButtonText] = useState(ONBOARD_TEXT);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [userLevel, setUserLevel] = useState<number | undefined>(undefined);
  const onboarding = useRef<MetaMaskOnboarding>();

  useEffect(() => {
    console.log(MetaMaskOnboarding.isMetaMaskInstalled() &&  accounts.length === 0)
    if(!MetaMaskOnboarding.isMetaMaskInstalled()) {
      return setUserLevel(0)
    }
    if(MetaMaskOnboarding.isMetaMaskInstalled() && accounts.length === 0) {
      return setUserLevel(1)
    } else {
      return setUserLevel(undefined)
    }
  },  [accounts])

  // onboarding
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      // @ts-ignore
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        // @ts-ignore
        .then((newAccounts) => {
          setAccounts(newAccounts);
        });
    }
  }, [onboarding]);

  // stop onboarding if account exists and update button
  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        onboarding.current?.stopOnboarding();
      } else {
        setAccountButtonText(CONNECT_TEXT);
      }
    }
  }, [accounts]);

  // account change
  useEffect(() => {
    const handleNewAccounts = (newAccounts: string[]) => {
      setAccounts(newAccounts);
    };
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        // @ts-ignore
        window.ethereum.removeListener('accountsChanged', handleNewAccounts);
      };
    }
  }, []);

  // network change
  useEffect(() => {
    // reloads the page when user attempts to change network
    // @ts-ignore
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.request({ method: 'eth_chainId' }).then((res: any) => {
        if (
          res !== POLYGON_NETWORK_CODE
          && res !== MUMBAI_NETWORK_CODE
        ) {
          return setNetworkError(true);
        }
      });
    }

    const handleChainChange = () => {
      window.location.reload();
    };

    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      // @ts-ignore
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        // @ts-ignore
        window.ethereum.removeListener('chainChanged', handleChainChange);
      };
    }
  }, [networkError]);

  // request account connection or onboard if metamask does not exist
  const onAccountClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      // @ts-ignore
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        // @ts-ignore
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current?.startOnboarding();
    }
  };

  return {
    accountButtonText, onAccountClick, networkError, accounts, isConnected: accounts.length, userLevel
  };
};
