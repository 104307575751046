import React, {useEffect, useState} from 'react';
import {Box, Button, Link} from "@mui/material";
import {useAccount} from "../hooks/useAccount";
import {useContract} from "../hooks/useContract";

const poll = async (fn: any, arg: any, ms: any) => {
  let result = await fn.getTransactionReceipt(arg);
  while (result === null) {
    await wait(ms);
    result = await fn.getTransactionReceipt(arg);
  }
  return result;
}

const wait = (ms = 1000) => {
  return new Promise(resolve => {
    console.log(`waiting ${ms} ms...`);
    setTimeout(resolve, ms);
  });
}

const cssAnimation  = {
  '@keyframes blinker': {
    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
    '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
    '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
    '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
    '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
    '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
    '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
    '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
    '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
    '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
    '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' }
  },
  animationName: 'blinker',
  animationDuration: '1s',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
}

const EggPage = () => {
  const { accounts } = useAccount();
  const { contract, provider } = useContract();
  const [nftLink, setNftLink] = useState();
  const [bunnyId, setBunnyId] = useState();
  const [eggId, setEggId] = useState();
  const [isHatchable, setIsHatchable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getNftId: () => Promise<void> = async () => {
      if(!contract || !accounts.length) return;
      const eggIds = await contract?.primary.walletOfOwner(accounts[0]);
      const bunnyIds = await contract?.secondary.walletOfOwner(accounts[0]);
      const latestEggId = eggIds[eggIds.length - 1];
      const latestBunnyId = bunnyIds[bunnyIds.length - 1];
      setEggId(latestEggId);
      setBunnyId(latestBunnyId);
    }
    const getHatchable: () => Promise<void> = async () => {
      if(!contract || !accounts.length) return;
      const isHatchContractSet = await contract?.primary.getIsHatchContractSet();
      setIsHatchable(!isHatchContractSet);
    }
    getHatchable();
    getNftId();
  }, [accounts, contract])

  useEffect(() => {
    if(eggId) {
      // @ts-ignore
      return setNftLink(`${process.env.REACT_APP_IPFS_EGG_IMAGE_CID}/${String(eggId)?.padStart(5, '0')}.png`);
    }
    if(bunnyId) {
      // @ts-ignore
      return setNftLink(`${process.env.REACT_APP_IPFS_BUNNY_IMAGE_CID}/${String(bunnyId)?.padStart(5, '0')}.png`);
    }
  }, [eggId, bunnyId])

  const handleHatchClick = async () => {
    try {
      const mintData = await contract?.primary.safeHatchMint(accounts[0], `${process.env.REACT_APP_IPFS_BUNNY_METADATA_CID}/{id}.json`);
      setIsLoading(true);
      await poll(provider, mintData.hash, 1000)
      // @ts-ignore
      setNftLink(`${process.env.REACT_APP_IPFS_BUNNY_IMAGE_CID}/${String(eggId)?.padStart(5, '0')}.png`);
    } catch (e) {
      console.warn(e)
    }
    setIsLoading(false);
  }

  const makeOpenSeaLink = ({ bunnyId, eggId }: { bunnyId?: string, eggId?: string }) => {
    if (bunnyId) return `${process.env.REACT_APP_OPEN_SEA_URL}${process.env.REACT_APP_SECONDARY_CONTRACT_ADDRESS}/${bunnyId}`
    if (eggId) return `${process.env.REACT_APP_OPEN_SEA_URL}${process.env.REACT_APP_MAIN_CONTRACT_ADDRESS}/${eggId}`
  }

  return (
    <Box display="flex">
      {nftLink ? <Box
        sx={{
          backgroundImage: `url(https://gateway.pinata.cloud/ipfs/${nftLink})`, height: '250px', width: '250px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', margin: 'auto', borderRadius: '5px',
          ...isLoading ? {...cssAnimation} : null
        }}
      /> : null}
      <Box display="flex" flexDirection="column" sx={{ margin: 'auto' }} gap={3}>
        {eggId || !bunnyId ? <Button variant="contained" onClick={handleHatchClick} disabled={isHatchable}>
          Hatch your egg
        </Button> : null}
        <Button
          component={Link}
          color="secondary"
          variant="contained"
          href={makeOpenSeaLink({ bunnyId, eggId })}
          target="_blank"
        >
          Go to opensea
        </Button>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Return to Minter
        </Button>
      </Box>
    </Box>
  );
};

export default EggPage;